import React from 'react'
import useGlobal from '~/hooks/useGlobal'

interface Props {
  children?: any
  className?: string
  to: string
  prefetch?: 'intent'
  onClick?: (e: any) => any
  target?: string
  rel?: string
}

const InternalLink: React.FC<Props> = ({
  children,
  to,
  prefetch,
  className,
  onClick,
  target,
  rel,
}) => {
  /** Use this instead of <Link> for links that still go to the old frontend. */
  const global = useGlobal()
  return (
    <a
      href={`${global.ENV.RAZZLE_BASE_URL}${to}`}
      className={
        'transition-all hover:text-brand-gold-hover hover:underline' +
        (className ? ` ${className}` : '')
      }
      onClick={onClick}
      target={target}
      rel={rel}
    >
      {children}
    </a>
  )
}

export default InternalLink
